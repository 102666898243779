import { NgModule } from '@angular/core';
import { FilterPipe } from './filter';
import { SafeHtmlPipe } from './safeHtml';
import { StateTrackingPipe } from './stateTracking';
import { TypeMapPipe } from './typeMap';


@NgModule({
  declarations: [FilterPipe, SafeHtmlPipe, StateTrackingPipe, TypeMapPipe],
  exports: [FilterPipe, SafeHtmlPipe, StateTrackingPipe, TypeMapPipe],
  providers: [],
})
export class PipesModule { }
