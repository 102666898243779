import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeMap'
})
export class TypeMapPipe implements PipeTransform {

  transform(value: string): string {
    switch(value) {
      case 'maps':
        return 'Mapa';
      case 'indoor':
        return 'Interior';
      default:
        return '';
    }
  }
}
