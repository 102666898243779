import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stateTracking'
})
export class StateTrackingPipe implements PipeTransform {

  transform(value: string): string {
    switch(value) {
      case 'progress':
        return 'En progreso';
      case 'incompleted':
        return 'Incompleto';
      case 'completed':
        return 'Completado';
        case 'stopped':
          return 'Detenido';
      default:
        return 'Estado Desconocido';
    }
  }
}
